import * as React from "react";
import LoginPage from './pages/LoginPage';
import ChangePasswordPage from './pages/ChangePasswordPage';
import { NotFound } from './pages/NotFound';
import './App.scss';
import { Route, Routes, useLocation, useRoutes } from 'react-router-dom';
import RegisterPage from './pages/RegisterPage';
import ForgotPasswordPage from './pages/ForgotPasswordPage';
import './index.css';
import { AnimatePresence, motion } from 'framer-motion';
import Layout from './components/Layout';
import GoogleLogin from './pages/GoogleLogin';
import GoogleRegister from './pages/GoogleRegister';
import InvitationPage from './pages/InvitationPage';
import ResetPasswordPage from './pages/ResetPasswordPage';
import LogoutPage from "./pages/LogoutPage";
import UnsubscribePage from "./pages/UnsubscribePage";

function App() {

  const location = useLocation();

  return (<>

    <AnimatePresence mode="wait">
      <Routes location={location} key={location.pathname}>
        <Route path="/google" element={<Layout />}>
          <Route path="login" element={<GoogleLogin/>} />
          <Route path="register" element={<GoogleRegister />} />
        </Route>
        <Route element={<Layout />} >
          <Route path="/" element={<LoginPage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/logout" element={<LogoutPage />} />
          <Route path="/invite/:inviteLink" element={<InvitationPage />} />
          <Route path="/unsubscribe/:hash" element={<UnsubscribePage />} />
          <Route path="/reset-password/:resetLink" element={<ResetPasswordPage />} />
          {/* <Route path="/register" element={<RegisterPage />} /> */}
          <Route path="/forgot-password" element={<ForgotPasswordPage />} />
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
    </AnimatePresence>
  </>
  );
}

export default App;
